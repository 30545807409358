<template>
  <div class="fixed z-10 inset-0">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <div class="spinner-center">
      <div class="nprogress relative w-full h-screen block">
        <div class="spinner spinner-icon"></div>
      </div>
    </div>
  </div>
</template>
